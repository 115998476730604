import React, { useEffect, useState } from 'react';
import '../Styles/style.css';
import AboutImg from '../../../Components/Assets/Images/AboutCenterImg.webp';

const Aboutus = () => {

   // For mobile layout Breakpoint conditions
   const [isMobile, setIsMobile] = useState(false);
   useEffect(() => {
       const handleResize = () => {
       setIsMobile(window.innerWidth <= 900); // Adjust the breakpoint as needed
       };
   
       window.addEventListener('resize', handleResize);
       handleResize(); // Set initial device type
   
       return () => {
       window.removeEventListener('resize', handleResize);
       };
   }, []);

  return (
    <>
    {isMobile? ( 
    <div className='container'>
      <div className="Section-main">
        <div className='row'>

          <div className='col-md-4 col-sm-12 leftContent'>
            <div className='primaryText'>
            <h6>
                  <span
                    className='circule'
                    id='pot'
                    style={{
                      position: 'relative',
                      top: '13px',
                      left: '13pxpx',
                      marginRight: '10px',
                      fontSize: '31px',
                    }}
                  >
                    &#186;
                  </span>
                  <span
                    className='line'
                    style={{ position: 'relative', left: '-10px' }}
                  >
                    &#8213;
                  </span>{' '}
           ABOUT US
                </h6>
            </div>
            <div className='about-us-box'>
            <div className='defaultText'>
              <h5>Bringing <span className="gradient-Text">Businesses & Tech </span>Together Is On<br/><span className="gradient-Text"> US.</span></h5>
            </div> 
            </div>              
          </div>

          <div className='col-md-4 col-sm-12 centerContent'>
            <div className='centerImg'>
              <img src={AboutImg}/>    
            </div>
          </div> 

          <div className='col-md-4 col-sm-12 rightContent'>
            <div className='rightTop'>
                <div className='rightDescription'>
                  <span>Our mission at Digital Chords is simple: to help businesses leverage the true power of <br/>technology. 
                  </span> 
                </div>
            </div>
            <div className='rightbottom'>
              <div className='rightDescription'>
                <span>Our mission at Digital Chords is simple: to help businesses leverage the true power of<br/> technology. 
                </span> 
              </div>  
            </div>       
          </div> 

        </div>
      </div>
    </div>
    ):(
      <div className='container'>
      <div className="Section-main">
        <div className='row'>

          <div className='col-md-4 col-sm-12 leftContent'>
            <div className='primaryText'>
            <h6>
                  <span
                    className='circule'
                    id='pot'
                    style={{
                      position: 'relative',
                      top: '13px',
                      left: '13pxpx',
                      marginRight: '10px',
                      fontSize: '31px',
                    }}
                  >
                    &#186;
                  </span>
                  <span
                    className='line'
                    style={{ position: 'relative', left: '-10px' }}
                  >
                    &#8213;
                  </span>{' '}
           ABOUT US
                </h6>
            </div>
            <div className='defaultText'>
              <div className='about-us-box'>
              <h5>Bringing <span className="gradient-Text">Businesses & Tech </span>Together Is On<span className="gradient-Text"> US.</span></h5>
            </div>               
          </div>
          </div>

          <div className='col-md-4 col-sm-12 centerContent'>
            <div className='centerImg'>
              <img src={AboutImg}/>    
            </div>
          </div> 

          <div className='col-md-4 col-sm-12 rightContent'>
            <div className='rightTop'>
              <div className='gradient-Text pt-2'>
                <span className='rightheading'>Cleary Set Mission </span>
              </div>
                <div className='rightDescription'>
                  <span>Our mission at Digital Chords is simple: to help businesses leverage the true power of technology. 
                  </span> 
                </div>
            </div>
            <div className='rightbottom'>
              <div className='gradient-Text p-0'>
                <span className='rightheading'>Cleary Set Mission </span>
              </div>
              <div className='rightDescription'>
                <span>Our mission at Digital Chords is simple: to help businesses leverage the true power of technology. 
                </span> 
              </div>  
            </div>       
          </div> 

        </div>
      </div>
    </div>
    )}  
  </>
  )
}

export default Aboutus;