import React, { useState, useEffect } from "react";
import SectionEndImage from "../../../Components/Assets/Images/homeSectionImg.webp";
import SectionEndImageMob from "../../../Components/Assets/Images/cImg.webp";
import "../Styles/style.css"

import { Link } from 'react-router-dom';

const HomeLastImg = () => {

        // For mobile layout Breakpoint conditions
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 900); // Adjust the breakpoint as needed
        };
    
        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial device type
    
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isMobile ? (
                    <>
                        <div className="container">
                            <div className="homelastImg">
                                <Link to={'/contact-us'}>
                                    <img src={SectionEndImageMob} alt="mobile" />
                                </Link>
                            </div>
                        </div>
                    </>
                ):
                (
                    <>
                    <div className="container">
                        <div className="bg-white homelastImg">
                            <Link to={'/contact-us'}>
                                <img src={SectionEndImage} alt="social-media-img" />
                            </Link>
                        </div>
                    </div>
                    </>
                )
            }
        </>
    )
}

export default HomeLastImg;