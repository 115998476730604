import React, { useState, useEffect } from 'react';
import './Contact.css';
import StyledButton from '../../Components/Buttons/Button';
import ContactImg from '../../Components/Assets/Images/cImg.webp';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Contactus = () => {
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    companyname: '',
    source: '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string()
    .matches(/^(\+92|0)(\d{10})$/, 'Invalid phone number') 
    .required('Phone is required'),
    companyname: Yup.string().required('Company Name is required'),
    message: Yup.string().required('Message is required'),
  });

  
  
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const formData = {
     firstname: values.firstname,
     lastname: values.lastname,
     email: values.email,
     companyname: values.companyname,
     phone: values.phone,
     source: values.source,
     message: values.message,
    };
   
    try {
      setIsLoading(true); 
     const queryParams = new URLSearchParams(formData).toString();
     const apiUrl = `https://svgnbrefmxr2zmno2j62e4i4gq0imgle.lambda-url.ap-southeast-1.on.aws/?${queryParams}`;
   
     const response = await fetch(apiUrl, {
       method: 'GET',
       headers: {
        'Content-Type': 'multipart/form-data', 
       },
     });
   
     if (response.ok) {
       console.log('Form submitted successfully!');
       setIsLoading(false);
       window.scrollTo(0, 0);
       setFormSubmitted(true);
     } else {
       console.error('Error submitting form');
       setIsLoading(false);
  
     }
     setIsLoading(false);
     setSubmitting(false);
     window.scrollTo(0, 0);
     setFormSubmitted(true);
     resetForm();
    } catch (error) {
     console.error('Error occurred:', error);
     setIsLoading(false);
     window.scrollTo(0, 0);
     setFormErrorSubmitted(true);
     // Handle other errors if needed
    }
   };
   
   
 

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900); // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial device type

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formErrorSubmitted, setFormErrorSubmitted] = useState(false);
  // const handleCloseAlert = () => {
  //   setFormSubmitted(false);
  // };
  // const handleErrorCloseAlert = () => {
  //   setFormErrorSubmitted(false);
  // };
  const handleCloseAlert = () => {
    setFormSubmitted(true); 
  };

  const handleErrorCloseAlert = () => {
    setFormErrorSubmitted(true); 
  };

  useEffect(() => {
    let timer;

    if (formSubmitted) {
      timer = setTimeout(() => {
        setFormSubmitted(false);
      }, 5000); 
    }

    if (formErrorSubmitted) {
      timer = setTimeout(() => {
        setFormErrorSubmitted(false);
      }, 5000); 
    }

    return () => {
      clearTimeout(timer); 
    };
  }, [formSubmitted, formErrorSubmitted]);

  return (
    <>
      <div className='contactpage'>
 
        <div className='container contactForm'>
        <div className='container'>
                <div className='row'>
                  <div className='col-md-1'></div>
                  <div className='col-md-10'>
                  {formSubmitted && (
        <div className='alert alert-success alert-dismissible fade show' role='alert'>
          Form submitted successfully!
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='alert'
            aria-label='Close'
            onClick={handleCloseAlert}
          ></button>
        </div>
      )}
      {formErrorSubmitted && (
        <div className='alert alert-danger alert-dismissible fade show' role='alert'>
          Something Went Wrong. Try Again Later !
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='alert'
            aria-label='Close'
            onClick={handleErrorCloseAlert}
          ></button>
        </div>
      )}
          </div>
           <div className='col-md-1'></div>
          </div>
          </div>
          {isMobile ? (
            <>
              <h2 className='contactHeading'>Let’s Talk!</h2>
              <span className='contactPara'>We are a full-service agency that builds immersive user experiences.</span>
            </>
          ) : (
            <>
              <h1 className='contactHeading'>Let’s Talk!</h1>
              <span className='contactPara'>We are a full-service agency that builds immersive user experiences.</span>
            </>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-1'></div>
                  <div className='col-md-5 col-sm-12 mb-3 finputfield'>
                    <Field type='text' id='firstname' name='firstname' placeholder='First Name*' className='form-control' />
                    <ErrorMessage name='firstname' component='div' />
                  </div>

                  <div className='col-md-5 col-sm-12 mb-3 finputfield'>
                    <Field type='text' id='lastname' name='lastname' placeholder='Last Name*' className='form-control' />
                    <ErrorMessage name='lastname' component='div' />
                  </div>
                  <div className='col-md-1'></div>

                  <div className='col-md-1'></div>
                  <div className='col-md-5 col-sm-12 mb-3 finputfield'>
                    <Field type='text' id='email' name='email' placeholder='Email*' className='form-control' />
                    <ErrorMessage name='email' component='div' />
                  </div>

                  <div className='col-md-5 col-sm-12 mb-3 finputfield'>
                    <Field type='text' id='phone' name='phone' placeholder='Phone*' className='form-control' />
                    <ErrorMessage name='phone' component='div' />
                  </div>
                  <div className='col-md-1'></div>

                  <div className='col-md-1'></div>
                  <div className='col-md-5 col-sm-12 mb-3 finputfield'>
                    <Field type='text' id='companyname' name='companyname' placeholder='Company Name*' className='form-control' />
                    <ErrorMessage name='companyname' component='div' />
                  </div>

                  <div className='col-md-5 col-sm-12 mb-3 finputfield'>
                    <Field type='text' id='source' name='source' placeholder='How did you hear about us?*' className='form-control' />
                  </div>
                  <div className='col-md-1'></div>

                  <div className='col-md-1'></div>
                  <div className='col-md-10 col-sm-12 mb-3 finputfield'>
                    <Field as='textarea' id='message' name='message' placeholder='Tell us more about your project.*' className='form-control' />
                    <ErrorMessage name='message' component='div' />
                  </div>
                  <div className='col-md-1'></div>

                  <div className='col-sm-12' style={{ textAlign: 'center', marginTop: '20px' }}>
                    <StyledButton label={isLoading ? <CircularProgress size={24} color='inherit' /> : 'Submit'}isDefault={true} type='submit' />
                    {/* <button type="submit">Submit</button> */}
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        
        </div>
      </div>
      <div className='contactDetail'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-sm-12'>
              <h3 className='heading2'>We’re Here</h3>
            </div>

            <div className='col-md-4 col-sm-12 h2contant'>
              <span>Head Office</span>
              {/* <h6>Lorem Ipsum is simply dummy text fot the typesetting and printing industry.</h6> */}
            </div>

            <div className='col-md-4 col-sm-12 h2contant'>
              <span>Office</span>
              {/* <h6>Lorem Ipsum is simply dummy text fot the typesetting and printing industry.</h6> */}
            </div>

            <div className='col-md-4 col-sm-12 h2contant'>
              <span>Partners</span>
              {/* <h6>Lorem Ipsum is simply dummy text fot the typesetting and printing industry.</h6> */}
            </div>

            <div className='col-1'></div>
            <div className='col-10 mt-4 cImage'>
              <Link to={'/contactus'}>
                <img src={ContactImg} alt='Contact' />
              </Link>
            </div>
            <div className='col-1'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
