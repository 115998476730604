import data from '../../Components/ContentComponent/data.json';
import Content from '../../Components/ContentComponent/Content';

const Index = () => {
  return (

      <> 
   <Content
   title={"Privacy Policy"}
   Content={data.privacypolicy.map((item) => {
    if (item.type === 'paragraph') {
      return <p className='context-text' key={item.id}>{item.content}</p>;
    } else if (item.type === 'list') {
      return <ul><li key={item.id}>{item.content}</li></ul>;
    }
    return null;
  })}
   />
    
   </>
  );
};

export default Index;
