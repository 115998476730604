import React from 'react';
import styled , { css } from 'styled-components';


const MainButton = styled.button`
  background-color: var(--primary);
  color: white;
  padding:15px 35px;
  width:180px;
  border-radius: 100px;
  ${props =>
    props.isDefault &&
    css`
      background-color: var(--default);
      color: #005695;
      padding: 14px 27px;
      width: 107px;
      border-radius: 100px;
      border:none;
     
    `}
`;
const StyledButton = ({ onClick, label, isDefault }) => {
  return (
    <MainButton onClick={onClick}  isDefault={isDefault}>
      {label}
    </MainButton>
  );
};

export default StyledButton;