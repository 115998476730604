import React, { useState, useEffect } from 'react'
import MobileImage from '../../Components/Assets/Images/mobileview.png'
import "./style.css"

const Content = ({title, Content}) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900); 
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); 
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
    {isMobile ? (
      <>
      <div className='container'>
      <div className='main-container-mobile'>
      <div className='row'>
        <div className='col col-md-12 col-lg-12'>
          <div className='main-content-title'>
          <h5>{title}</h5>
          </div>
          {Content}
          <div className='Mobile-Image-div'>
            <img src={MobileImage} alt="social-media"/>
          </div>
        </div>
      </div>
      </div>
    </div>
      </>

    ):(
      <>
        <div className='container'>
          <div className='main-container'>
    <div className='row'>
      <div className='col col-md-12 col-md-12 col-lg-12'>
        <div className='main-content-title'>
        <h1>{title}</h1>
        </div>
      
        {Content}
      
      </div>
    </div>
    </div>
  </div>
      </>
   )}
   </>
  
  )
}

export default Content