import React, { useState, useEffect } from 'react';
import bannerImg from '../../../../src/Components/Assets/Images/HomeBanner.webp';
import mobilebanner from '../../../Components/Assets/Images/Mobile_banner.webp'


const Banner = () => {
  const [mobileHeader, setMobileHeader] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMobileHeader(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {mobileHeader ? (
        <>
        <div className='heading_Mob'>
          <h2 className='heading_h2'>Creativity</h2>
          <h2 className='heading_h2'>+</h2>
          <h2 className='heading_h2'>Experience</h2> 
        </div>
        <div className='text_Div'>
        <p className='text'>We are a full-cycle agency that delivers turnkey projects. </p>
        </div>
        <div className='img-banner-mobile'>
          <img src={mobilebanner} alt='globe'/>
        </div>
        </>
      ) : (
        <>
        <div className='image-banner'>
          <img src={bannerImg} alt='Banner'  />
        </div>
        </>
      )}
    </>
  );
};

export default Banner;