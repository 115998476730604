import React from "react";
import Home from "../../Pages/Home/Home";
import Contactus from "../../Pages/Contactus/Contact";
import PrivacyPolicy from "../../Pages/PrivacyPolicy/index";
import TermsandConditions from "../../Pages/TermsandConditions/index";

export const Routess = [
    {
      path: "/",
      component: <Home />,
    },
    {
      path: "/contact-us",
      component: <Contactus />,
    },
        
      {
        path: "/privacy-policy",
        component: <PrivacyPolicy />,
      },
      {
        path: "/terms-conditions",
        component: <TermsandConditions />,
      },
     
     
  ];
  