import React, { useState, useEffect } from 'react';
import "../Styles/SectionStyle.css";

const Section = ({ imageSrc, title, description, mobileImage }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900); // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial device type

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  

  return (
    <>
      {isMobile ? (
        <div className='naibDiv'>
          <div className="container">
            <div className="Section-main-mobile">
              <img src={mobileImage} alt="development" className='Section-mobile-image' />
            </div>
          </div>
        </div>
      ) : (
        <div className="naibDiv">
          <div className="container">
            <div className="Section-main">
              <div className="Section-1-Content">
                <div className='text-line'>
                  <h4>{title}</h4>
                  <h6>{description}</h6>
                </div>
              </div>
              <div className="section-1-container">
                <img src={imageSrc} alt={title} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Section;
