
import './App.css';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from "./Components/Hoc/index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Routess} from "./Components/Routes/Routess"
import ScollToTop from './Components/ScollToTop';


function App() {

  return (
  <>
  <BrowserRouter>
  <ScollToTop  />
  <Routes>
        <Route path='/' element={<Layout />} >
            {Routess.map((data) => {
              return <Route exact path={data.path} element={data.component} />;
            })}
      
            </Route>
            </Routes>
      </BrowserRouter>
  </>

  );
}

export default App;
