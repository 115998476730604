import React from 'react'
import './Styles/style.css'
import HomeCentral from './HomeCentral'
const Index = () => {
  return (
   <>                     
  <HomeCentral/>
 
   </>
  )
}

export default Index