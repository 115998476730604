// ThemeProviderWrapper.js
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from './index';

const ThemeProviderWrapper = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        {children}
      </React.Fragment>
    </ThemeProvider>
  );
};

export default ThemeProviderWrapper;
