import React, { useState, useEffect } from 'react';
import section2img from '../../../Components/Assets/Images/section_2_img.webp'
const Section2 = ({ imageSrc, title, description }) => {

  // For mobile layout Breakpoint conditions
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
      const handleResize = () => {
      setIsMobile(window.innerWidth <= 900); // Adjust the breakpoint as needed
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Set initial device type
  
      return () => {
      window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <>
      {
        isMobile? (
          
            <>
           
                  <div className='text_main'>

                    <h3 className='mobTitle gradient-Text'><span className='transparent-Text'>Let’s</span> Develop & Launch</h3> 
                    <h3><span className='transparent-Text'>Customer-First</span></h3>
                    <h3 className='solution'>Solutions</h3>
                    <div className='primaryText'>
                     <span>{description}</span> </div>
                    </div>
                <div className="section-2-img mt-4 ">
                  <img src={section2img} alt={title} />
                </div>
              
            </>
                
         
        ):
        (
            <div className="container">
              <div className="Section-main">
                <div className="Section-1-Content">
                  <div className='text-line'>
                    <h3><span className='defaultText'>Let’s</span> Develop & Launch <span className='defaultText'>Customer-First Solutions</span></h3>  
                    <span className='description'>{description}</span>
                  </div>
                </div>
                <div className="section-1-container ml-4">
                  <img src={imageSrc} alt={title} width={450} height={450} style={{textAlign:"center"}}/>
                </div>
              </div>
            </div>
        )
      }
    </>
  );
};

export default Section2;
