
import './Footer.css'
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Logo from "../../Assets/Images/webLogo.webp";
import Facebook from "../../Assets/Images/facebook.png"
import Instagram from "../../Assets/Images/insta.png";
import Twitter from "../../Assets/Images/twitter.png";
import Youtube from "../../Assets/Images/youtube.png";
import Linkedin from "../../Assets/Images/linkedin.png"
import { Link, useNavigate } from "react-router-dom";
import StyledButton from '../../Buttons/Button';



const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900); 
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial device type
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  function handleClick() {
    navigate("/contact-us");
  }
  return (
   <>
    {isMobile ? (
      <>
     
     <div className="container">
  <div className="row">
  <div className='footer-content-1'>
    <Link to="/">
    <img src={Logo} alt='logo' style={{minWidth:"100px"}}/>
    </Link>

    <Link to="mailto:connect@digitalchords.com" className='footer-text1'> <p>connect@digitalchords.com</p></Link>

<Link to="tel:+923214154859" className='footer-text1'> <p>+92 321 415 4859</p></Link>

<Link to="https://www.google.com/maps/place/Xcentric+Services/@31.4716427,74.387499,17z/data=!4m6!3m5!1s0x391905b6fff0c439:0x61d9cc9ae15a3ce0!8m2!3d31.4716382!4d74.3900739!16s%2Fg%2F11ggbn0nq3?coh=164777&entry=tt&shorturl=1" className='footer-text1'> <p>Plaza No. 10, Block K, DHA Phase 1, Lahore.</p></Link>
    </div>
    <div className="col col-md-6 col-lg-6">
    <div className="footer-content-1-mobile">
  
    <Link to="/privacy-policy" className='footer-text'> <p>Privacy Policy</p></Link>
     </div>
    </div>
    <div className="col col-md-6 col-lg-6">
    <div className="footer-content-2-mobile">

    <Link to="/terms-conditions" className='footer-text'> <p>Terms & Conditions</p></Link>
    </div>
    </div>
</div>
    </div>
 
   </>
    ):(
      <>
      
       <div className="main-conatiner">
       <div className='mainSection'>
   
  <div className="container">
  <div className="row">
    <div className="col col-md-3 col-lg-3">
    <div className='footer-content-1'>
    <Link to="/">
    <img src={Logo} alt='logo' style={{minWidth:"100px"}}/>
    </Link>

    <Link to="mailto:connect@digitalchords.com" className='footer-text1'> <p>connect@digitalchords.com</p></Link>

<Link to="tel:+923214154859" className='footer-text1'> <p>+92 321 415 4859</p></Link>

<Link to="https://www.google.com/maps/place/Xcentric+Services/@31.4716427,74.387499,17z/data=!4m6!3m5!1s0x391905b6fff0c439:0x61d9cc9ae15a3ce0!8m2!3d31.4716382!4d74.3900739!16s%2Fg%2F11ggbn0nq3?coh=164777&entry=tt&shorturl=1" className='footer-text1'> <p>Plaza No. 10, Block K, DHA Phase 1, Lahore.</p></Link>
    </div>
    </div>
    <div className="col col-md-6 col-lg-6">
      <div className='footer-content-2'>
      <Link to="/privacy-policy" className='footer-text'> <p>Privacy Policy</p></Link>
      <Link to="/terms-conditions" className='footer-text'> <p>Terms & Conditions</p></Link>
      </div>
     
        <div className='social-buttons'>
        <img src={Facebook} alt='fb'/>
        <img src={Instagram} alt='insta'/>
        <img src={Youtube} alt='youtube'/>
        <img src={Linkedin} alt='linkedin'/>
        <img src={Twitter} alt='twitter'/>
     
      </div>
    </div>
    <div className="col col-md-3 col-lg-3 ">
      <div className="footer-content-3">
    <StyledButton onClick={handleClick} label="Contact us"  />
    </div>
    </div>
  </div>
  </div>
  </div>
  </div>

 
      </>
    )}
   <div className="footer-last-container">

   <div className="container">
  <div className="row">
    <div className="col col-md-12 col-lg-12">
      <div  className='footer-subhead-text'>
      <p>All Rights Reserved © Digital Chords 2024</p>
      </div>
      </div>
      </div>
      </div>

      </div>
   </>
  )
}

export default Footer