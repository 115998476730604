import React from 'react'
import './Styles/style.css'

import SectionImage4 from "../../Components/Assets/Images/section4.webp";

import Section2 from './Components/Section2';
import Banner from './Components/Banner';
import HomeLastImg from './Components/imageSection';
import S2headings from './Components/Section2Headings';


import OurClients from './Components/OurClients';
import Development from './Components/Development';
import DevelopmentSection from './Components/DevelopmentSection';
import Aboutus from './Components/Section3Aboutus';
const Index = () => {
  return (
   <>
<Banner/>
                        
  <DevelopmentSection/>

      <S2headings />

      <Section2
        imageSrc={SectionImage4}
        description="We help businesses launch digital solutions from conception to development using the latest technology and programming languages."
      />
      <Aboutus/>
      <OurClients/>
      <Development/>
      <HomeLastImg/>
   </>
  )
}

export default Index