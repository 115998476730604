import React, { useState, useEffect } from 'react'
import "../Styles/OurClients.css"
import imageArray from './ImageArray';

const OurClients = () => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 900); // Adjust the breakpoint as needed
      };
    
      window.addEventListener('resize', handleResize);
      handleResize(); // Set initial device type
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  return (
    <>
       {isMobile ? (
      <>
<div className='container'>
           <div className="Section-main">
          <div className='main_Div_Dev_mobile'>
            <div className='div_Dev_4'>
        <div className='row'>
            <div className='col col-md-12 col-lg-12'>
                <div className='primaryText'>
                <h6>
                  <span
                    className='circule'
                    id='pot'
                    style={{
                      position: 'relative',
                      top: '13px',
                      left: '13pxpx',
                      marginRight: '10px',
                      fontSize: '31px',
                    }}
                  >
                    &#186;
                  </span>
                  <span
                    className='line'
                    style={{ position: 'relative', left: '-10px' }}
                  >
                    &#8213;
                  </span>{' '}
             OUR CLIENTS
                </h6>
                </div>
                <div className='our-clients-head'>
                    <div className='defaultText'>
                    <h3>Inspiring growth <br/> possibilities for <span className="gradient-Text">Brands</span></h3>
                  
                    </div>
                            
                <div className='parent-mobile'>
      {imageArray.map((image, index) => (
        <div className='btn-gradient-2' key={index}>
          <img src={image.src} alt={image.alt} className='tags-image'  />
        </div>
      ))}
    </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    </div>
    </div>
      </>
       ):(
        <>
         <div className='container'>
    <div className="Section-main">
         
            <div className='div_Dev_4'>
       <div className='container'>
        <div className='row'>
            <div className='col col-md-12 col-lg-12'>
                <div className='primaryText'>
                <h6>
                  <span
                    className='circule'
                    id='pot'
                    style={{
                      position: 'relative',
                      top: '13px',
                      left: '13pxpx',
                      marginRight: '10px',
                      fontSize: '31px',
                    }}
                  >
                    &#186;
                  </span>
                  <span
                    className='line'
                    style={{ position: 'relative', left: '-10px' }}
                  >
                    &#8213;
                  </span>{' '}
               OUR CLIENTS
                </h6>
                </div>
                <div className='our-clients-head'>
                    <div className='defaultText'>
                    <h3>Inspiring growth possibilities<br/> for <span className="gradient-Text">Brands</span></h3>
                  
                    </div>
                
                <div className='parent'>
      {imageArray.map((image, index) => (
        <div className='btn-gradient-2' key={index}>
          <img src={image.src} alt={image.alt} className='tags-image'  />
        </div>
      ))}
    </div>
            </div>
        </div>
        </div>
        </div>
 
  </div>
  </div>
  </div>
 
    </>
       )}
    </>
  )
}

export default OurClients