import React, { useState, useEffect } from "react";
import '../Styles/style.css';

const S2headings = () => {

        // For mobile layout Breakpoint conditions
        const [isMobile, setIsMobile] = useState(false);
        useEffect(() => {
            const handleResize = () => {
            setIsMobile(window.innerWidth <= 900); // Adjust the breakpoint as needed
            };
        
            window.addEventListener('resize', handleResize);
            handleResize(); // Set initial device type
        
            return () => {
            window.removeEventListener('resize', handleResize);
            };
        }, []);

    return (
        <>
            {isMobile ? 
                (
                        <div className='container'>
                            <div className='Section-Three'>
                                <h5 className="gradient-Text">Forward-Thinking <br/> Processes</h5>
                            </div>
                            <div>
                                <h5 className="transparent-Text">Designs That Put <br/> People</h5>
                            </div>
                        </div>
                ):
                (
                        <div className='container'>
                            <div className='Section-Three'>
                                <h3 className="gradient-Text">Forward-Thinking <br/> Processes</h3>
                            </div>
                            <div>
                                <h3 className="transparent-Text">Designs That Put <br/> People</h3>
                            </div>
                        </div>
                )
            }
        </>
    )
}

export default S2headings;