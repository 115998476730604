import React, {useState, useEffect} from 'react'
import './Header.css'
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../Assets/Images/webLogo.webp'
import cross from '../../Assets/Images/button.webp'
import { Offcanvas } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {  useLocation } from 'react-router-dom';

const Header = () => {
  const [url, setUrl] = useState(null);
  const location = useLocation(); 

const [mobileHeader , setMobileHeader] = useState (false);

useEffect(() => {
  const handleResize = () => {
    setMobileHeader(window.innerWidth <= 900); 
  };

  window.addEventListener('resize', handleResize);
  handleResize(); 

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
useEffect(() => {
  setUrl(location.pathname);
}, [location]);


const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
<>
  {mobileHeader ? (
    <>
  <div className='butn'>
    <img src={cross} alt='icon' onClick={handleShow}/>
    </div>
      <div className='logoDiv'>
      <Link to="/">
      <img className='logodiv' src={logo} alt='logo' />
      </Link>
    </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header >
        <div className='menuIcon'>
        <Link to="/">
      <img className='logodive' alt='logo' src={logo}/>
      </Link>
    </div>
        <AiOutlineCloseCircle onClick={handleClose} size={49} color="rgba(0, 86, 149, 1)"  /> 
        </Offcanvas.Header>
        <Offcanvas.Body>
        <div className="menu_main">
            <div className="menu_items">
              <div className="menu_item_name">
                <Link
                  className="link_name"
                  to="/"
                  onClick={() => setShow(false)}
                >
       <span className='circule' id="pot" style={{position: 'relative' , top: '8px' , left: '7px'}}>&#186;</span><span className='line'>&#8213;</span>           Home 
                </Link>

                <Link
                  className="link_name"
                  to="/"
                  onClick={() => setShow(false)}
                >
       <span className='circule' style={{position: 'relative' , top: '8px' , left: '7px'}}>&#186;</span><span className='line'>&#8213;</span>         Services
                </Link>

                <Link
                  className="link_name"
                  to="/"
                  onClick={() => setShow(false)}
                >
          <span className='circule' style={{position: 'relative' , top: '8px' , left: '7px'}}>&#186;</span><span className='line'>&#8213;</span>        About Us
                </Link>

                <Link
                  className="link_name"
                  to="/"
                  onClick={() => setShow(false)}
                >
          <span className='circule' style={{position: 'relative' , top: '8px' , left: '7px'}}>&#186;</span><span className='line'>&#8213;</span>        Blog
                </Link>

                <Link
                  className="link_name"
                  to="/"
                  onClick={() => setShow(false)}
                >
          <span className='circule' style={{position: 'relative' , top: '8px' , left: '7px'}}>&#186;</span><span className='line'>&#8213;</span>        Contact us
                </Link>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
 </>
  ):(
<>
<div className='mainDiv'>
      <div className='logo'>
      <Link to="/">
      <img className='logo' src={logo} alt='logo'/>
      </Link>
      </div>
      <div className='Menus'>
    <Navbar id="navbar-nav">
      <Nav className="ml-auto">
      <Nav.Link as={Link}   to="/" >Home</Nav.Link>
        <Nav.Link as={Link}  to="/">Services</Nav.Link>
        <Nav.Link as={Link}   to="/">About Us</Nav.Link> 
        <Nav.Link as={Link}   to="/">Blogs</Nav.Link> 
        <Nav.Link as={Link} active  className={"underline" + (url === "/contact-us" ?" active" : "")}  to="/contact-us"><marquee>Contact Us</marquee></Nav.Link>
      </Nav>
    </Navbar>
      </div>
    </div>
</>
  )}
</>
  )
}

export default Header
