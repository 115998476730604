
import React, { useState, useEffect } from 'react';
import img1 from '../../../Components/Assets/Images/Development.webp';
import img2Dev from '../../../Components/Assets/Images/second_Div_section_1.webp'
import StyledButton from '../../../Components/Buttons/Button';

const Banner = () => {
  const [mobileHeader, setMobileHeader] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMobileHeader(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {mobileHeader ? (
        <>
        <div className='container'>
        <div className="Section_main_Dev">
          <div className='container'>
              <div className='primaryText'>
                <h6>
                  <span
                    className='circule'
                    id='pot'
                    style={{
                      position: 'relative',
                      top: '13px',
                      left: '13pxpx',
                      marginRight: '10px',
                      fontSize: '31px',
                    }}
                  >
                    &#186;
                  </span>
                  <span
                    className='line'
                    style={{ position: 'relative', left: '-10px' }}
                  >
                    &#8213;
                  </span>{' '}
                  BLOGS
                </h6>
              </div>
              <div className='about-us-box'>
              <h5 className='dev_heading'>
                Unveiling The Evolving Development World
              </h5>
              </div>
              <div className='btn_Blog'>
                <button className='btn_blog_Dev'>More Post</button>
           
              </div>
              </div>

              <div className='second_main_div_Dev'>

              <div className='second_div_Dev'>

               <div className='second_Div_section_1'>
                <img src={img2Dev} alt='dev-img'/>
               </div>
               <div className='second_Div_section_2'>
                <button className='web_Dev'>Web Development</button>
                <h6 className='heading_Div'>Why Should You Choose Digital Chords for Website Development?</h6>
               </div>

              </div>
              </div>
        </div>
        </div>
        </>
      ) : (
        <>
          <div className='container'>
        <div className="Section-main">
          <div className='main_Div_Dev'>
            <div className='div_Dev_1'>
              <div className='primaryText'>
                <h6>
                  <span
                    className='circule'
                    id='pot'
                    style={{
                      position: 'relative',
                      top: '13px',
                      left: '13pxpx',
                      marginRight: '10px',
                      fontSize: '31px',
                    }}
                  >
                    &#186;
                  </span>
                  <span
                    className='line'
                    style={{ position: 'relative', left: '-10px' }}
                  >
                    &#8213;
                  </span>{' '}
                  BLOGS
                </h6>
              </div>
              <div className='about-us-box'>
              <h4 className='dev_heading'>
                Unveiling The Evolving Development World
              </h4>
              </div>
              <div className='btn_Blog'>
                <button className='btn_blog_Dev'>More Post</button>
              </div>
            </div>
            <div className='div_Dev_2'>
              <img src={img1} alt='img' />
              <button className='web_Dev_dev'>Web Development</button>
              <h5 className='heading_Div_Dev'>Why Should You Choose Digital Chords for Website Development?</h5>
            </div>
          </div>
          </div>
          </div>
        </>
      )}
    </>
  );
};

export default Banner;
