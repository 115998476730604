import React, { useState, useEffect } from 'react';
import Section from '../Components/Section';
import SectionImage from "../../../Components/Assets/Images/Section-image-1.png";
import SectionImage2 from "../../../Components/Assets/Images/Mobile.png";
import SectionImage3 from "../../../Components/Assets/Images/Software.png";
import SoftwareDevelopment from "../../../Components/Assets/Images/softwareDevelopment.webp";
import WebDevelopment from "../../../../src/Components/Assets/Images/webDevelopment.webp";
import AppDevelopment from "../../../../src/Components/Assets/Images/appDevelopment.webp";

const DevelopmentSection = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900); // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial device type

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <Section mobileImage={SoftwareDevelopment} />
          <Section mobileImage={WebDevelopment} />
          <Section mobileImage={AppDevelopment} />
        </>
      ) : (
        <>
          <Section
            imageSrc={SectionImage}
            title="Web Development"
            description="Want better digital recognition? We've got you covered with web design solutions."
          />

          <Section
            imageSrc={SectionImage2}
            title="App Development"
            description="Ready to launch an app? Let us develop one that's enjoyable to the users."
          />

          <Section
            imageSrc={SectionImage3}
            title="Software Development"
            description="Got an idea? Our team is here to make cutting-edge software out of it."
          />
        </>
      )}
    </>
  );
};

export default DevelopmentSection;
