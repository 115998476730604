
import CyberDirect from "../../../Components/Assets/Images/Cyber.webp";
import Helpful from "../../../Components/Assets/Images/Helpful.webp";
import Cissom from "../../../Components/Assets/Images/Cissom.webp";
import TheMoltyFool from "../../../Components/Assets/Images/moltyfool.webp";
import Packt from "../../../Components/Assets/Images/Packt.webp";
import Peel from "../../../Components/Assets/Images/Peel.webp";

const imageArray = [
    { src: CyberDirect, alt: 'cyber direct' },
    { src: Cissom, alt: 'Cissom' },
    { src: Helpful, alt: 'Helpful' },
    { src: TheMoltyFool, alt: 'TheMoltyFool' },
    { src: Peel, alt: 'peel' },
    { src: Packt, alt: 'cyber direct' },
  ];

  export default imageArray;