// theme.js
import { createGlobalStyle } from 'styled-components';

export const theme = {
  colors: {
    primary: '#005695',
    secondary: '#6c757d',
    default:'#E5EEF4'
  },
};

export const GlobalStyle = createGlobalStyle`
  /* Override Bootstrap's primary and secondary colors */
  :root {
    --primary: ${props => props.theme.colors.primary};
    --secondary: ${props => props.theme.colors.secondary};
    --default: ${props => props.theme.colors.default};
  }
`;
